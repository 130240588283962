import React, { useEffect, useState, useRef } from 'react'
import { TextField, Button, FormGroup } from '@material-ui/core'
import styles from './contactForm.module.css'
import Recaptcha from 'react-recaptcha'
import firebase from 'firebase/app'
import Script from 'react-load-script'
import Axios from 'axios'

const ContactForm = () => {

    const sitekey = process.env.GATSBY_RECAPTCHA_API_KEY;
    const recaptchaRef = useRef({})

    const config = {
        apiKey: process.env.GATSBY_FIREBASE_API_KEY,
        authDomain: process.env.GATSBY_AUTH_DOMAIN,
        databaseURL: process.env.GATSBY_DB_URL,
        projectId: process.env.GATSBY_PROJECT_ID,
        storageBucket: process.env.GATSBY_STORAGE_BUCKET,
        messagingSenderId: process.env.GATSBY_MESSAGE_SENDER_ID,
        appId: process.env.GATSBY_APP_ID,
        measurementId: process.env.GATSBY_MEASUREMENT_ID,
    }

    let [contact, setContact] = useState({name: '', email: '', number: '', message: ''})
    let [errors, setErrors] = useState({name: '', email: '', number: '', message: ''})  
    let [verified, setVerified] = useState(false)
    let [showCaptcha, setShowCaptcha] = useState(false)

    useEffect(() => {
        setShowCaptcha(true)
    }, []);

    const validateForm = () => {
        const req = 'This field is required'
        let isError = false;
        const errorsMessages = {
            name: "",
            email: "",
            number: "",
            message: ""
        };

        if(contact.name === '')  {
            isError = true;
            errorsMessages.name = req;  
        }
        if(contact.number === '') {
            isError = true;
            errorsMessages.number = req; 
        }
        if(contact.email === '') {
            isError = true;
            errorsMessages.email = req; 
        }
        if(contact.message === '') {
            isError = true;
            errorsMessages.message = req; 
        }
        // check name only alphabets
        var nameRegx = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/
        if(!nameRegx.test(contact.name)) {
            isError = true;
            errorsMessages.name = "Please enter a valid name"
        }

        // check email proper email
        var emailRegx = /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(!emailRegx.test(contact.email)) {
            isError = true;
            errorsMessages.email = "Please enter a valid email"
        }
        
        // check phone only numbers
        var phoneRegx = /[0-9]{10}/
        if(!phoneRegx.test(contact.number)) {
            isError = true;
            errorsMessages.number = "Please enter a valid 10 digit mobile number"
        }

        setErrors({
            ...errors,
            ...errorsMessages
        });

        return isError;
    }

    const HandleSubmit = event => {
        //set up firebase
        event.preventDefault()
        if (!firebase) {
            firebase.initializeApp(config)
        }
        // get form data
        const contactData = contact;
        
        // validate the form
         const error = validateForm()

        // check if there is error
        if (!error) {

            // check if the user has verified with captcha
            if (verified) {

                // send request to the server
                Axios.post(
                    "https://us-central1-selvaraju-architects-website.cloudfunctions.net/submit",
                    contactData
                ).then(res => {
                    // set the contact details to null
                    setContact({name: '', number: '', email: '', message: ''})
                    // reset captcha
                    recaptchaRef.current.reset();
                    // set captcha verified and form validated to false
                    setVerified(false)
                    // set the error texts to false
                    setErrors({name: '', email: '', number: '', message: ''})
                })
                .catch(error => {
                    return null;
                })
            } else {
                alert("Please verify if you are a human.")
            }
        }
        
    }

    const HandleChange = event => {
        if (event.target.name === "name") {
            setContact({...contact, name: event.target.value})
        } else if (event.target.name === "email") {
            setContact({...contact, email: event.target.value})
        } else if (event.target.name === "number") {
            setContact({...contact, number: event.target.value})
        } else if (event.target.name === "message") {
            setContact({...contact, message: event.target.value})
        }
    }

    const recaptchaLoaded = event => {

    }

    const verifyRecaptcha = response => {
        if(response) {
            setVerified(true)
        }
    }

    return(
        <div className={styles.formContainer}>
            <form onSubmit={HandleSubmit} autoComplete="off">
                <FormGroup>
                    <label className={styles.formLabel}>Name :</label>
                    <TextField 
                        value={contact.name}
                        helperText={errors.name}
                        className={styles.input} 
                        onChange={(e) => HandleChange(e)} 
                        name="name"
                    />
                </FormGroup>
                <FormGroup>
                    <label className={styles.formLabel}>Email :</label>
                    <TextField 
                        value={contact.email}
                        helperText={errors.email}
                        className={styles.input} 
                        onChange={(e) => HandleChange(e)}
                        name="email"
                    />
                </FormGroup>
                <FormGroup>
                    <label className={styles.formLabel}>Phone Number :</label>
                    <TextField 
                        value={contact.number}
                        helperText={errors.number}
                        className={styles.input} 
                        onChange={(e) => HandleChange(e)} 
                        name="number"
                    />
                </FormGroup>
                <FormGroup>
                    <label className={styles.formLabel}>Message :</label>
                    <TextField 
                        value={contact.message}
                        helperText={errors.message}
                        className={styles.input} 
                        onChange={(e) => HandleChange(e)} 
                        name="message" 
                        rows={4} 
                        multiline 
                    />
                </FormGroup>
                <div style={{marginTop: "35px"}}>
                    {
                        showCaptcha ? 
                            <Recaptcha 
                                ref={recaptchaRef}
                                sitekey={sitekey}
                                theme="dark"
                                render="explicit"
                                onloadCallback={recaptchaLoaded}
                                verifyCallback={verifyRecaptcha}
                            /> 
                        : null 
                    }
                </div>
                <Button type="submit" className={styles.submit}>Submit</Button>
            </form> 
            <Script
                url="https://www.google.com/recaptcha/api.js?render=explicit"
                onLoad={recaptchaLoaded}
                attributes={{async: true, defer: true}}
            />
        </div>
    )
}

export default ContactForm;