import React from 'react'
import Img from 'gatsby-image'
import Layout from '../components/layout/layout'
import { Container, Grid, Typography } from '@material-ui/core';
import styles from '../stylesheets/about.module.css';
import ContactForm from '../components/contactForm/contactForm';
import SEO from '../components/seo';
import Landing from '../components/landing/landing'

export const query = graphql`
    {
        allFile(filter: {extension: {regex: "/(jpg)|(jpeg)/"}, sourceInstanceName: {eq: "assets"}, id: {eq: "2b003275-f9fb-53ea-9633-499be832f9b1"}}) {
            edges {
                node {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`

export default ({ data }) => {

    return(
        <Layout pageTitle="Contact">
            <SEO title="Contact" />
            <Landing type="Contact us" />
            <Container className={styles.aboutContainer}>
                <Grid container className={styles.contactContainer}>
                    <Grid className={styles.gridItem} item sm={12} md={6}>
                        <Typography style={{width: "100%"}} className={styles.aboutHeading} variant="h5">Get in touch with us</Typography>
                        <Img className={styles.aboutImg} fluid={data.allFile.edges[0].node.childImageSharp.fluid} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={12}>
                                <div className={styles.address}>
                                    <Typography variant="body2">No 22, Yafa building, </Typography>
                                    <Typography variant="body2">Model school road,</Typography>
                                    <Typography variant="body2">Thousand lights, Chennai - 600006</Typography>
                                </div>
                                <div className={styles.contactInfo}>
                                    <Typography variant="body2">Phone: <a href="tel:04428294255">+91-44-2829-4255</a></Typography>
                                    <Typography variant="body2">Mobile: <a href="tel:919940034506">+91-9791-079621</a></Typography>
                                    <Typography variant="body2">Email: selvarajuarch@gmail.com</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={12}>
                                <ContactForm /> 
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container  className={styles.contactContainer}>
                    <Grid item xs={12} md={6} className={styles.mapItem}>
                    <iframe 
                        title="map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.650118368609!2d80.24971461430513!3d13.05792881651238!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266402c327283%3A0xc9e6a626ee57a4e2!2sSelvaraju%20Architects!5e0!3m2!1sen!2sin!4v1586506679111!5m2!1sen!2sin" 
                        width="600" 
                        height="450" 
                        frameBorder="0" 
                        style={{border: '0'}}
                        allowFullScreen="" 
                        aria-hidden="false" 
                        tabIndex="0">
                    </iframe>
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    )
}